import { AppQueryConfig } from "QueryTypes";
import { leagueQueryDefaultOptions } from "./leagueHelpers";
import { ClubhouseRoundTeamDto, CreateTeamCommandPayload } from "Models";

const urls = {
  leagueTeamsBase(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/teams`;
  },
  leagueCreateTeams(facilityId: string, leagueId: string): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/team`;
  },
  leagueUpdateTeam(
    facilityId: string,
    leagueId: string,
    teamId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/team/${teamId}/`;
  },
  leagueDeleteTeam(
    facilityId: string,
    leagueId: string,
    teamId: string
  ): string {
    return `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${facilityId}/league/${leagueId}/team/${teamId}/`;
  },
};

export const getTeamsByFacilityAndLeagueId = (
  facilityId: string,
  leagueId: string
): AppQueryConfig => ({
  url: urls.leagueTeamsBase(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "GET",
  },
  transform: (responseJson: ClubhouseRoundTeamDto[]) => {
    let obj: { [id: string]: ClubhouseRoundTeamDto } = {};

    responseJson.map((team) => {
      return (obj[team?.id] = team);
    });

    return {
      leagueTeams: {
        byLeagueId: {
          [leagueId]: obj,
        },
      },
    };
  },
  update: {
    leagueTeams: (oldValue, newValue) => ({
      byLeagueId: {
        ...oldValue?.leagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const makeCreateLeagueTeamMutation = (
  facilityId: string,
  leagueId: string,
  body: CreateTeamCommandPayload
): AppQueryConfig => ({
  url: urls.leagueCreateTeams(facilityId, leagueId),
  options: {
    ...leagueQueryDefaultOptions(),
    method: "POST",
  },
  body,
  transform: (responseJson: ClubhouseRoundTeamDto) => {
    return {
      leagueTeams: {
        byLeagueId: {
          [leagueId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    leagueTeams: (oldValue, newValue) => ({
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const makeUpdateLeagueTeamMutation = (
  facilityId: string,
  leagueId: string,
  teamId: string,
  body: CreateTeamCommandPayload
): AppQueryConfig => ({
  url: urls.leagueUpdateTeam(facilityId, leagueId, teamId),
  queryKey: `makeUpdateLeagueTeamMutation:${leagueId}-${teamId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "PUT",
  },
  body,
  transform: (responseJson: ClubhouseRoundTeamDto) => {
    return {
      leagueTeams: {
        byLeagueId: {
          [leagueId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  update: {
    leagueTeams: (oldValue, newValue) => ({
      byLeagueId: {
        ...oldValue?.byLeagueId,
        ...{
          [leagueId]: {
            ...oldValue?.byLeagueId?.[leagueId],
            ...newValue.byLeagueId[leagueId],
          },
        },
      },
    }),
  },
});

export const makeDeleteLeagueTeamMutation = (
  facilityId: string,
  leagueId: string,
  teamId: string
): AppQueryConfig => ({
  url: urls.leagueDeleteTeam(facilityId, leagueId, teamId),
  queryKey: `makeDeleteLeagueTeamMutation:${leagueId}-${teamId}`,
  options: {
    ...leagueQueryDefaultOptions(),
    method: "DELETE",
  },
  transform: (responseJson: ClubhouseRoundTeamDto) => {
    return {
      leagueTeams: {
        byLeagueId: {
          [leagueId]: { [responseJson.id]: responseJson },
        },
      },
    };
  },
  optimisticUpdate: {
    leagueTeams: (reduxState) => {
      delete reduxState.byLeagueId[leagueId][teamId];
      return reduxState;
    },
  },
});
