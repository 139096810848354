import { PlayerUser } from "Models";
import { Gender, TeeType } from "types/enums";
import { titleCase } from "utils/enums/enumHelper";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { customStyles } from "utils/ui/uiHelpers";
import FormattedUserSearchOption from "../round-control/round-configuration-form/FormattedUserSearchOption";
import AsyncSelect from "react-select/async";
import debounce from "debounce-promise";
import { userSearch } from "utils/userSearch/userSearchHelpers";
import { useUpdateUserAgHandicap } from "modules/user/userHooks";
import { useAppDispatch } from "utils/hooks";
import { aGHandicapAction } from "ui-modules/nav/navSlice";
import { FaArrowRight } from "react-icons/fa";

const defaultValues = {
  displayName: "",
  username: "",
  email: "",
  gender: "",
  teeType: "",
  handicap: "",
  agHandicap: "",
  ttl: "",
};

const LockerUserEditForm = () => {
  const dispatch = useAppDispatch();
  const methods = useForm();
  const watchAll = methods.watch();
  const inputClassname =
    "cursor-not-allowed appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-inner placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-gray-50 dark:bg-zinc-600 dark:text-gray-200";

  const handleSearchOnChange = (option: PlayerUser | null) => {
    option
      ? Object.entries(option).map(([key, value]) => {
          return methods.setValue(key, value);
        })
      : methods.reset(defaultValues);
  };

  const [, updateUserAgHandicap] = useUpdateUserAgHandicap(
    methods.getValues("id"),
    {
      agHandicap: methods.getValues("agHandicap"),
      handicap: Number(methods.getValues("handicap")),
    }
  );

  const onSubmit = async (formData: PlayerUser) => {
    const result = await updateUserAgHandicap({});
    let message = "";

    if (
      methods.control._formState.dirtyFields["handicap"] &&
      methods.control._formState.dirtyFields["agHandicap"]
    ) {
      message = `aG Handicap set to ${formData.agHandicap} and Index set to ${formData.handicap} for ${formData.displayName}`;
    } else if (methods.control._formState.dirtyFields["handicap"]) {
      message = `Index set to ${formData.handicap} for ${formData.displayName}`;
    } else {
      message = `aG Handicap set to ${formData.agHandicap} for ${formData.displayName}`;
    }

    if (result?.status === 200) {
      showCustomNotification(message);

      methods.reset(defaultValues);
      dispatch(aGHandicapAction({ isOpen: false }));
    } else {
      showCustomNotification(
        "An error occurred. Please try again",
        GenericNotificationType.ERROR
      );
    }
  };

  const getAsyncOptions = (inputValue: string) => {
    return new Promise<PlayerUser[]>((resolve) => {
      if (inputValue.length > 3) {
        const options = userSearch(inputValue);
        resolve(options);
      } else {
        const options: PlayerUser[] = [];
        resolve(options);
      }
    });
  };

  const loadOptions = (inputValue: string) => {
    return getAsyncOptions(inputValue);
  };

  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  return (
    <>
      <div className="my-8" data-testid="lockerUserEditFormContainer">
        <div className="">
          <div className="pb-4">
            <label data-testid="lockerUserEditFormSearchField">
              <AsyncSelect
                RequireAtLeastOne
                backspaceRemovesValue={false}
                noOptionsMessage={() => "No players found."}
                loadOptions={(query) => debouncedLoadOptions(query)}
                getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
                filterOptions={false}
                onChange={(option) => handleSearchOnChange(option)}
                formatOptionLabel={(option, { context }) => {
                  return (
                    <FormattedUserSearchOption
                      value={option}
                      context={context}
                    />
                  );
                }}
                styles={customStyles}
                placeholder={"Start typing to search for player..."}
                isClearable={true}
                autoFocus
                id="lockerUserEditFormSearchField"
              />
            </label>
          </div>
          <FormProvider {...methods}>
            <form
              className="space-y-6"
              onSubmit={methods.handleSubmit(onSubmit)}
              data-testid="form"
            >
              <div>
                <label
                  htmlFor="displayName"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Display Name
                </label>
                <div className="mt-1">
                  <Controller
                    name="displayName"
                    control={methods.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        readOnly
                        autoComplete="Display name"
                        className={inputClassname}
                        value={watchAll["displayName"]}
                        defaultValue=""
                        data-testid="displayName"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="username"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Username
                </label>
                <div className="mt-1">
                  <Controller
                    name="username"
                    control={methods.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        readOnly
                        autoComplete="Username"
                        className={inputClassname}
                        defaultValue={watchAll["username"]}
                        data-testid="username"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <Controller
                    name="email"
                    control={methods.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="email"
                        readOnly
                        className={inputClassname}
                        defaultValue={watchAll["email"]}
                        data-testid="email"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="gender"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Gender
                </label>
                <div className="mt-1">
                  <Controller
                    name="gender"
                    control={methods.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        className={inputClassname}
                        readOnly
                        defaultValue={
                          watchAll["gender"]
                            ? titleCase(Gender[watchAll["gender"]])
                            : ""
                        }
                        data-testid="gender"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="teeType"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Tee Type
                </label>
                <div className="mt-1">
                  <Controller
                    name="teeType"
                    control={methods.control}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="text"
                        className={inputClassname}
                        readOnly
                        defaultValue={
                          watchAll["teeType"]
                            ? titleCase(TeeType[watchAll["teeType"]])
                            : ""
                        }
                        data-testid="teeType"
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="handicap"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  Your Index
                </label>
                <div className="mt-1">
                  <Controller
                    name="handicap"
                    control={methods.control}
                    defaultValue={0}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandGreen focus:border-brandGreen sm:text-sm dark:bg-zinc-600 dark:text-gray-200"
                        defaultValue={watchAll["handicap"]}
                        data-testid="handicap"
                        min={-10}
                        step={0.1}
                        max={54}
                      />
                    )}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="agHandicap"
                  className="block text-sm font-medium text-gray-700 dark:text-gray-200"
                >
                  aG Handicap
                </label>
                <div className="mt-1">
                  <Controller
                    name="agHandicap"
                    control={methods.control}
                    defaultValue={0}
                    render={({ field }) => (
                      <input
                        {...field}
                        type="number"
                        data-testid="agHandicap"
                        required
                        min={-5}
                        step={0.1}
                        max={54}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-brandGreen focus:border-brandGreen sm:text-sm dark:bg-zinc-600 dark:text-gray-200"
                      />
                    )}
                  />
                </div>
              </div>
              <Controller
                name="ttl"
                control={methods.control}
                render={({ field }) => (
                  <input
                    {...field}
                    defaultValue={watchAll["ttl"]}
                    type="hidden"
                  />
                )}
              />
              <Controller
                name="id"
                control={methods.control}
                render={({ field }) => (
                  <input
                    {...field}
                    required
                    defaultValue={watchAll["id"]}
                    type="hidden"
                  />
                )}
              />
              <div>
                <button
                  type="submit"
                  value="Send to Sim"
                  disabled={methods.getValues("displayName") ? false : true}
                  className="send-to-sim w-full poppins bg-brandYellow hover:bg-brandGreen text-black hover:text-white text-sm tracking-wide py-2 px-4 rounded shadow-md hover:animate-pulse focus:ring-2 focus:ring-blue-600 active:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <span className="inline text-md font-semibold">
                    Submit <FaArrowRight />
                  </span>
                </button>
              </div>
            </form>
          </FormProvider>
        </div>
      </div>
    </>
  );
};
export default LockerUserEditForm;
