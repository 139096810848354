import { useState } from "react";
import { DateTime } from "luxon";
import { AnimatePresence, motion } from "framer-motion";
import { FiEdit2 } from "react-icons/fi";
import { styles } from "../Leagues.styes";
import { BsPeople } from "react-icons/bs";
import { MdGroup } from "react-icons/md";
import { League, LeagueRound, ClubhouseRoundDto } from "Models";
import { useAppDispatch } from "utils/hooks";
import { RiDeleteBinLine } from "react-icons/ri";
import { titleCase } from "utils/enums/enumHelper";
import { modalAction } from "ui-modules/modals/modalSlice";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { ModalType, modalName } from "utils/modals/modalHelpers";
import {
  LeagueHandicapMethod,
  LeagueRoundCompetitionType,
  LeagueScoring,
  LeagueType,
} from "types/enums";
import {
  useDeleteLeagueRound,
  getSelectedLeagueRound,
  useGetClubhouseLeagueRoundDto,
} from "modules/leagues/leagueRoundHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import ReactTooltip from "react-tooltip";
import ConfirmDeleteAlert from "components/shared/ConfirmDeleteAlert";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { useFlags } from "launchdarkly-react-client-sdk";
import { eventStartDatePassed } from "modules/events/eventHelpers";
import { createLeagueRoundTrayAction } from "ui-modules/nav/navSlice";
import {
  useGetClubhouseLeagueDto,
  useIsMatchLeague,
  useSelectedLeagueTypeIsTeam,
} from "modules/leagues/leagueHooks";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import LeagueRoundCardDetails from "./LeagueRoundCardDetails";
import LeagueRoundLeaderboardModal from "../LeagueRoundLeaderboardModal";
import LeagueRoundTeamLeaderboardModal from "../LeagueRoundTeamLeaderboardModal";
import MatchPlayRoundLeaderboardModal from "../MatchPlayRoundLeaderboardModal";

const LeagueRoundCard: React.FC<LeagueRoundCardProps> = (props) => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const isTeamLeague = useSelectedLeagueTypeIsTeam();
  const isMatchPlayLeague = useIsMatchLeague();

  const { league, leagueRound } = props;
  const { leagueScoring } = useFlags();

  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [selectedRound, setSelectedRound] = useState<LeagueRound>();
  const [isOpen, setIsOpen] = useState(false);

  const [, deleteRound] = useDeleteLeagueRound(
    league.id,
    currentFacilityId,
    leagueRound?.id
  );
  const [, , refreshLeagueRound] = useGetClubhouseLeagueRoundDto(
    currentFacilityId,
    league.id,
    leagueRound?.id
  );
  const [, , refreshLeagueDto] = useGetClubhouseLeagueDto(
    currentFacilityId,
    league.id
  );

  const deleteConfirmModalName = modalName(
    `{${ModalType.ConfirmDeleteAlert}-leagueRound-${leagueRound.id}}`
  );

  const startDatePassed = eventStartDatePassed(leagueRound?.startPlayDate);

  const handleEditLeagueRound = (leagueRoundId: string) => {
    let response;
    response = refreshLeagueRound();

    response?.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        showCustomNotification("Round has been loaded");
        dispatch(
          setSelectedRoundId({
            selectedLeagueRoundId: leagueRoundId,
          })
        );
        setIsOpen(false);
        dispatch(createLeagueRoundTrayAction({ isOpen: true }));
      } else {
        showCustomNotification(
          getApiResponseErrorMessage(res),
          GenericNotificationType.ERROR
        );
      }
    });
  };

  const handleDeleteLeagueRound = (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setModalOpen();
  };

  const handleCreateRoundGroupsClick = () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: leagueRound.id,
      })
    );
    dispatch(
      modalAction({ isOpen: true, modalName: ModalType.LeagueRoundGroups })
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_VIEW_GROUPS);
  };

  const handleViewRoundLeaderboardClick = () => {
    dispatch(
      setSelectedRoundId({
        selectedLeagueRoundId: leagueRound.id,
      })
    );
    setShowLeaderboard(!showLeaderboard);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_VIEW_LEADERBOARD);
  };

  const setModalOpen = () => {
    dispatch(
      modalAction({
        isOpen: true,
        modalName: deleteConfirmModalName,
      })
    );
  };

  const handleConfirm = (value: boolean) => {
    let response;
    if (value) {
      response = deleteRound();
      response?.then((res) => {
        if (isApiStatusSuccess(res?.status)) {
          showCustomNotification(
            "Round deleted successfully",
            GenericNotificationType.SUCCESS
          );
          refreshLeagueDto();
        } else {
          showCustomNotification(
            "An error occurred",
            GenericNotificationType.ERROR
          );
        }
      });
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_DELETE);
    }
  };

  const handleGetLeagueRound = (roundId: string) => {
    const selectedRound = getSelectedLeagueRound(
      currentFacilityId,
      league.id,
      roundId
    );

    selectedRound.then((selectedRound) => {
      setSelectedRound(selectedRound);
      setIsOpen(!isOpen);
    });
  };

  return (
    <>
      <ConfirmDeleteAlert
        modalName={deleteConfirmModalName}
        bodyText={`Are you sure you want to delete ${leagueRound?.name}?`}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
        callback={handleConfirm}
      />
      <motion.div
        variants={containerChildMotionVariants}
        key={`league-${league?.id}-round-${leagueRound?.id}`}
      >
        <div
          className={`${styles.leagueCard} shadow hover:shadow-sm hover:rounded-xl hover:border-brandDarkGray `}
        >
          <div className={styles.leagueCardHeaderRow}>
            <div className="w-full">
              <span className={`${styles.leagueTitle} mb-2 mt-1`}>
                {leagueRound.name}
              </span>
            </div>
            <div className={styles.leagueEditDeleteContainer}>
              <button
                type="button"
                className={`${styles.leagueEditButton} border-r border-gray-200`}
                data-for={`edit-${leagueRound.name}`}
                data-tip={`Edit ${leagueRound.name}`}
                onClick={() =>
                  handleEditLeagueRound(leagueRound?.id.toString())
                }
              >
                <FiEdit2 className={styles.leagueButtonIcon} />
              </button>
              <button
                type="button"
                className={styles.leagueDeleteButton}
                data-for={`edit-${leagueRound.name}`}
                data-tip={
                  startDatePassed
                    ? "Deleting is disabled since the round has already started."
                    : `Delete ${leagueRound.name}`
                }
                onClick={(e) => handleDeleteLeagueRound(e)}
                disabled={startDatePassed}
              >
                <RiDeleteBinLine className={styles.leagueButtonIcon} />
              </button>
            </div>
          </div>
          <div className={styles.leagueCardHeaderRow}>
            <small className="rounded-lg bg-darkCream dark:bg-zinc-400 dark:text-black py-1 px-2 poppins h-6">
              <BsPeople className="h-4 w-4 mr-0.5" />
              {league.participantCount}
              {league.settings.leagueType === LeagueType.TEAM
                ? " teams"
                : " players"}
            </small>
            {leagueRound.numberOfGroups > 0 && (
              <small
                className={`ml-0.5 rounded-md px-2 pt-1 poppins h-6 dark:text-gray-50`}
              >
                <MdGroup className="h-4 w-4 mr-0.5 stroke-zinc-600 dark:stroke-gray-100" />
                {leagueRound.numberOfGroups} groups
              </small>
            )}
          </div>
          <div className={styles.leagueCardDateRow}>
            <div className={styles.leagueCardCol}>
              <div className={styles.titleRow}>Scoring Format</div>
              <div className={styles.titleDescription}>
                {titleCase(LeagueScoring[league?.settings.leagueScoringType])}
              </div>
              <div className={styles.titleRow}>Handicap Format</div>
              <div className={styles.titleDescription}>
                {titleCase(
                  LeagueHandicapMethod[league?.settings.handicapMethod]
                )}
              </div>
              <div className={styles.titleRow}>Competition Type</div>
              <div className={styles.titleDescription}>
                {titleCase(
                  LeagueRoundCompetitionType[leagueRound.competitionType]
                )}
              </div>
            </div>
            <div className={styles.leagueCardCol}>
              <div className={styles.titleRow}>Start Date</div>
              <div className={`${styles.dateDescription} text-[10px]`}>
                {DateTime.fromISO(leagueRound.startPlayDate).toLocaleString({
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
              <div className={styles.titleRow}>End Date</div>
              <div className={styles.dateDescription}>
                {DateTime.fromISO(leagueRound.endPlayDate).toLocaleString({
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
              <div className={styles.titleRow}>Course</div>
              <div className={`${styles.titleDescription} h-10`}>
                {leagueRound.course}
              </div>
            </div>
          </div>

          {isOpen && selectedRound && (
            <LeagueRoundCardDetails selectedRound={selectedRound} />
          )}
          <div className={`${styles.leagueCardDetailsRow}`}>
            <div className={styles.leagueCardCol}>
              <div className="">
                <button
                  onClick={() =>
                    handleGetLeagueRound(leagueRound?.id.toString())
                  }
                  className="text-brandDarkGrey dark:text-gray-200 font-bold text-xs border-b border-gray-300"
                >
                  {isOpen ? "Close" : "See all"}
                </button>
              </div>
            </div>
          </div>
          <div className={styles.leagueCardRow}>
            <button
              className={styles.leagueViewRoundsButton}
              onClick={() => handleCreateRoundGroupsClick()}
            >
              Groupings
            </button>
          </div>
          <div className={styles.leagueCardFooterRow}>
            <div className="w-full">
              {leagueScoring && (
                <button
                  type="button"
                  className={styles.leagueOtherButton}
                  onClick={() => handleViewRoundLeaderboardClick()}
                >
                  Leaderboard
                </button>
              )}
            </div>
          </div>
        </div>
        <ReactTooltip
          backgroundColor="#f5f5f5"
          textColor="#000"
          border={true}
          borderColor="#777777"
          id={`edit-${leagueRound.name}`}
          place="bottom"
        ></ReactTooltip>
      </motion.div>
      <AnimatePresence>
        {showLeaderboard && (
          <motion.div
            key={`league-${league.name}-leaderboard`}
            className={styles.leaderboardModalContainer}
            variants={containerChildMotionVariants}
          >
            {isTeamLeague ? (
              <LeagueRoundTeamLeaderboardModal
                callback={() => setShowLeaderboard(!showLeaderboard)}
                leagueRound={leagueRound}
              />
            ) : isMatchPlayLeague ? (
              <MatchPlayRoundLeaderboardModal
                callback={() => setShowLeaderboard(!showLeaderboard)}
                leagueRound={leagueRound}
              />
            ) : (
              <LeagueRoundLeaderboardModal
                callback={() => setShowLeaderboard(!showLeaderboard)}
                leagueRound={leagueRound}
              />
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
export default LeagueRoundCard;

type LeagueRoundCardProps = {
  league: League;
  leagueRound: ClubhouseRoundDto;
  editLeague?: boolean;
};
