import store from "store/rootReducer";

export const isModalOpen = (modalName: string): boolean => {
  return store.getState().modals?.modalSettings[modalName]?.isOpen || false;
};

export enum ModalType {
  MoveRoundModal = "moveRoundModal",
  MultiSimControlModal = "multiSimControlModal",
  SimControlModal = "simControlModal",
  RoundControlModal = "roundControlModal",
  ScorecardModal = "scorecardModal",
  RoundConfigurationDeleteConfirm = "roundConfigurationDeleteConfirm",
  SendSimulatorMessage = "sendSimulatorMessageModal",
  League = "leagueDeleteModal",
  ConfirmDeleteAlert = "confirmDelete",
  LeagueRoundGroups = "leagueRoundGroupsModal",
  RoundPresets = "roundPresetsModal",
  QueueModal = "queueModal",
}

export const modalName = (prefix: string, key?: string | null): string => {
  return key ? `${prefix}-${key}` : prefix;
};
