import { useState } from "react";
import { styles } from "./Leagues.styes";
import { MdExpandMore } from "react-icons/md";
import { MdOutlineClose } from "react-icons/md";
import { MatchResults, ScorecardMatchResponseDto } from "ScorecardModels";
import { Controller, useFormContext } from "react-hook-form";
import {
  RankedScorecardPlayer,
  RoundLeaderboardPlayerDto,
  RoundPar,
} from "Models";
import {
  convertPlayerLeaderboardToScorecard,
  createMatchResultHolesWon,
  teamColorHelper,
} from "modules/scorecard-lookup/scorecardHelpers";
import ScorecardHolesLayout from "components/scorecard/ScorecardHolesLayout";

type LeaguePlayerRankedScorecardProps = {
  callback?: any;
  playerRankedScorecard?: RankedScorecardPlayer;
  opponentRoundLeaderboard: RoundLeaderboardPlayerDto;
  playerRoundLeaderboard: RoundLeaderboardPlayerDto;
  finalizeScorecards?: boolean;
  index?: number;
  showNetScore?: boolean;
  roundPar: RoundPar;
  isTeamLeague?: boolean;
  matchResults: ScorecardMatchResponseDto;
  participantNumber: number;
};

const LeagueMatchRankedScorecard: React.FC<LeaguePlayerRankedScorecardProps> = (
  props
) => {
  const {
    playerRankedScorecard,
    opponentRoundLeaderboard,
    playerRoundLeaderboard,
    finalizeScorecards,
    index,
    showNetScore,
    callback,
    matchResults,
    participantNumber,
  } = props;
  const { control } = useFormContext();
  const [showScorecard, setShowScorecard] = useState(false);

  const participantScorecard = convertPlayerLeaderboardToScorecard(
    playerRoundLeaderboard,
    showNetScore || false,
    participantNumber
  );

  const opponentScorecard = convertPlayerLeaderboardToScorecard(
    opponentRoundLeaderboard,
    showNetScore || false,
    participantNumber === 1 ? 2 : 1
  );

  let createMatchResults: MatchResults = {
    winner: 0,
    holesWon: createMatchResultHolesWon(matchResults, showNetScore || false),
    finalResult: "",
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      e.currentTarget.blur();
    }
  };

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={`w-full  ${
        showScorecard ? "bg-gray-50 dark:bg-zinc-800 rounded-lg shadow" : ""
      }`}
    >
      <div
        key={playerRankedScorecard?.playerGuid}
        className={`${styles.modalResultsRow} ${
          showScorecard ? "dark:text-black " : "dark:text-gray-200"
        }`}
      >
        <div className="col-span-4 ml-2">
          {matchResults && participantNumber && (
            <div
              className={`inline-block mt-1 mr-2 h-2 w-2 ${teamColorHelper[participantNumber]}`}
            ></div>
          )}
          {playerRankedScorecard?.playerName}
        </div>
        <div className="hidden md:inline-block col-span-2"></div>
        {matchResults ? (
          <>
            <div className="hidden md:inline-block col-span-1">
              {participantNumber === 1
                ? showNetScore
                  ? matchResults?.netMatchWinner?.participant1Score?.holesWon
                  : matchResults?.grossMatchWinner?.participant1Score?.holesWon
                : showNetScore
                ? matchResults?.netMatchWinner?.participant2Score?.holesWon
                : matchResults?.grossMatchWinner?.participant2Score?.holesWon}
            </div>
            <div className="hidden md:inline-block col-span-1">
              {participantNumber === 2
                ? showNetScore
                  ? matchResults?.netMatchWinner?.participant1Score?.holesTied
                  : matchResults?.grossMatchWinner?.participant1Score?.holesTied
                : showNetScore
                ? matchResults?.netMatchWinner?.participant2Score?.holesTied
                : matchResults?.grossMatchWinner?.participant2Score?.holesTied}
            </div>
          </>
        ) : (
          <div className="hidden md:inline-block col-span-2">
            {playerRankedScorecard?.agHandicap}
          </div>
        )}

        {finalizeScorecards ? (
          <div className="col-span-2 text-left">
            <Controller
              name={`players.[${index}].points`}
              control={control}
              rules={{
                required: "Please enter a point value",
              }}
              defaultValue={playerRankedScorecard?.currentRound?.points.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="number"
                  min={0}
                  max={100}
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                  onKeyDown={(e) => handleKeyDown(e)}
                />
              )}
            />
            <Controller
              name={`players.[${index}].playerRoundLeaderBoardId`}
              control={control}
              defaultValue={playerRankedScorecard?.playerRoundLeaderBoardId.toString()}
              render={({ field }) => (
                <input
                  {...field}
                  type="hidden"
                  className="border-1 border-gray-200 rounded-lg w-20 text-xs font-normal p-1"
                />
              )}
            />
          </div>
        ) : (
          <div className="col-span-2 pl-2">
            <span className="border-1 border-transparent rounded-lg w-20 text-xs font-normal p-1">
              {playerRankedScorecard?.currentRound?.points}
            </span>
          </div>
        )}
        <div className="col-span-1">
          {showNetScore && playerRankedScorecard?.agHandicap}
        </div>
        <div className="col-span-1 items-center text-right pr-2">
          <div
            onClick={() => setShowScorecard(!showScorecard)}
            className={styles.showScorecardButton}
          >
            {showScorecard ? (
              <>
                <MdOutlineClose
                  className={showScorecard ? "font-bold size-4" : "hidden"}
                />
              </>
            ) : (
              <>
                <MdExpandMore className="size-4" />
              </>
            )}
          </div>
        </div>
      </div>
      {showScorecard && (
        <div className="text-xs bg-gray-50 dark:bg-zinc-600 rounded-bl-lg rounded-br-lg dark:rounded-none p-4">
          <ScorecardHolesLayout
            playerScorecard={[participantScorecard, opponentScorecard]}
            allowEditing={true}
            scorecardId={playerRoundLeaderboard?.scoreCardId}
            showNetScore={showNetScore}
            callback={callback}
            matchResults={matchResults && createMatchResults}
          />
        </div>
      )}
    </div>
  );
};

export default LeagueMatchRankedScorecard;
