import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { roundSettingsAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { styles } from "./RoundSettingsTray.styles";
import SettingsContainer from "./SettingsContainer";
import { enableBackgroundScroll } from "utils/ui/uiHelpers";

const RoundSettingsTray = () => {
  const dispatch = useAppDispatch();

  const isRoundSettingsTrayOpen = useSelector(
    (state: RootState) => state.nav?.roundSettings?.isOpen
  );

  const setRoundSettingsTray = () => {
    enableBackgroundScroll();
    dispatch(roundSettingsAction({ isOpen: !isRoundSettingsTrayOpen }));
  };

  return (
    <AnimatePresence>
      {isRoundSettingsTrayOpen && (
        <>
          <motion.div
            initial={{ x: "100%" }}
            animate={{
              x: 0,
            }}
            exit={{
              x: "100%",
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.4 }}
            className={styles.trayContainer}
          >
            <div className={styles.headerContainer}>
              <div className={styles.headerCloseButton}>
                <button
                  onClick={() => setRoundSettingsTray()}
                  className={styles.button}
                >
                  Close
                </button>
              </div>
              <div className={styles.headerTitle}>
                <h2 className={styles.trayHeader}>Round Presets</h2>
              </div>
              <div className={styles.headerRightButton}></div>
            </div>
            <div className={styles.innerContainer}>
              <SettingsContainer />
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            exit={{
              opacity: 0,
            }}
            transition={{ type: "spring", bounce: 0, duration: 0.2 }}
            onClick={() => setRoundSettingsTray()}
            className={styles.overlay}
          />
        </>
      )}
    </AnimatePresence>
  );
};
export default RoundSettingsTray;
