export const styles = {
  listViewContainer:
    "-mx-4 mt-2 overflow-hidden shadow-md border border-gray-300 ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-2 md:rounded-lg px-2 md:px-0",
  listViewColumn:
    "flex w-1/4 xl:w-1/6 justify-start contents-center items-center text-left text-xs text-gray-400 dark:text-gray-200 p-4 pl-0 tracking-normal",
  listViewColumnHideOnSmall:
    "xl:flex xl:w-1/6 xl:justify-start contents-center items-center hidden text-xs text-gray-400 dark:text-gray-200 p-4 pl-0 tracking-normal",
  listViewEditColumn:
    "flex flex-col w-1/6 mr-2 text-left text-xs text-gray-400 dark:text-gray-200 p-4 pl-0 tracking-normal",
  listViewTableBody: "bg-white",
  row: [
    "hover:bg-gray-100",
    "flex flex-row",
    "flex-wrap",
    "lg:flex-nowrap",
    "content-center",
  ].join(" "),
  column: ["flex", "flex-1", "text-left", "text-md", "font-bold"].join(" "),
  label: [].join(" "),
  columnSmall: [
    "flex",
    "m-0",
    "md:my-auto",
    "text-left",
    "md:mx-4",
    "text-md",
    "font-semibold",
  ].join(" "),
  checkbox:
    "text-brandGreen rounded focus:ring-0 ring-opacity-0 border-2 border-gray-500 text-md mr-2 mb-0.5",
  span: [
    "text-xs",
    "text-gray-400",
    "hidden",
    "md:contents",
    "inline-block",
  ].join(" "),
  threeDotMenuListItem:
    "border-b py-2 pl-2 pr-6 text-left text-xs text-gray-500 dark:text-gray-200 bg-white dark:bg-zinc-800 hover:bg-gray-200",
  listViewButton:
    "flex h-6 w-6 text-center p-1 items-center text-sm hover:text-gray-700 active:shadow-lg cursor-pointer rounded-full border border-gray-200 bg-white text-gray-400 dark:text-white dark:bg-zinc-800 dark:border-2",
};
export default styles;
