import { motion } from "framer-motion";
import { FaSort } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { BsArrowLeft } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { useToasterStore } from "react-hot-toast";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { globalStyles } from "components/globalStyles";
import { useCallback, useEffect, useState } from "react";
import {
  ClubhouseRoundTeamDto,
  CreateTeamCommandPayload,
  League,
  LeagueRosterEntryDto,
  PatchTeamCommandPayload,
} from "Models";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { leagueQueryDefaultOptions } from "modules/leagues/leagueHelpers";
import { sortStringArrayByProperty } from "utils/arrays/arrayHelpers";
import {
  getApiResponseErrorMessage,
  isApiStatusSuccess,
} from "utils/api/apiHelper";
import {
  GenericNotificationType,
  showCustomNotification,
} from "utils/notifications/notificationHelpers";
import { styles } from "./RosterAndGroups.styles";
import { Gender, MotionVariantType } from "types/enums";
import {
  dailyQueueContainer,
  dailyQueueListItemVariant,
  listItemTopDownShow,
} from "utils/animations/animationHelper";
import { useGetClubhouseLeagueDto } from "modules/leagues/leagueHooks";
import {
  useCreateLeagueTeam,
  useLeagueTeamsByFacilityAndLeagueId,
} from "modules/leagues/leagueTeamsHooks";
import Team from "./Team";
import {
  useLeagueRosterByLeagueId,
  useRemovePlayerFromLeagueRoster,
} from "modules/leagues/leagueRosterHooks";
import CreateLeagueRosterSearch from "./CreateLeagueRosterSearch";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import axios from "axios";
import Tippy from "@tippyjs/react";

type CreateTeamLeagueRosterProps = {
  callback?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLeague: League;
};
type AddPlayerToTeamProps = {
  player: LeagueRosterEntryDto;
};

const CreateTeamLeagueRoster: React.FC<CreateTeamLeagueRosterProps> = (
  props
) => {
  const { selectedLeague } = props;
  const toastIsActive = useToasterStore().toasts.length > 0 ? true : false;
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [selectedPlayer, setSelectedPlayer] = useState<LeagueRosterEntryDto>();
  const [leagueRoster, , refreshLeagueRoster] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );
  const [
    leagueTeams,
    ,
    refreshLeagueTeams,
  ] = useLeagueTeamsByFacilityAndLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );
  const [createTeamQueryState, createTeam] = useCreateLeagueTeam(
    currentFacilityId,
    selectedLeague?.id
  );
  const [, removePlayerFromRoster] = useRemovePlayerFromLeagueRoster(
    currentFacilityId,
    selectedLeague?.id,
    selectedPlayer?.userGuid || ""
  );
  const [, , updateLeague] = useGetClubhouseLeagueDto(
    currentFacilityId,
    selectedLeague?.id
  );

  const [teamName, setTeamName] = useState<string>("");
  const [group, setAddToGroup] = useState<LeagueRosterEntryDto[]>([]);
  const [showGroup, setShowGroup] = useState<boolean>(false);
  const [showTeamView, setShowTeamView] = useState<boolean>(false);
  const [sortAscending, setSortAscending] = useState<boolean>(true);
  const [sortProperty, setSortProperty] = useState<string>(
    "firstName" || "aGHandicapIndex" || "lastName" || "teamName"
  );

  const teamSize = selectedLeague?.settings.leagueTeamSize || 2;

  const sortedLeagueRoster = sortStringArrayByProperty(
    leagueRoster,
    sortProperty as keyof LeagueRosterEntryDto,
    sortAscending
  );

  const handleRemovePlayerFromRoster = async (player: LeagueRosterEntryDto) => {
    if (window.confirm(`Remove ${player.userName} from roster?`)) {
      setSelectedPlayer(player);
    }
  };

  const handleNameChange = (event: React.FormEvent<HTMLInputElement>) => {
    setTeamName(event?.currentTarget?.value);
  };

  const handleShowGroupCreate = () => {
    setShowGroup(true);
    setShowTeamView(false);
  };

  const handleCreateNewTeam = async () => {
    let payload = {} as CreateTeamCommandPayload;

    payload.name = teamName.replace(/\s+/g, " ").trim();
    payload.teamMembers = group.map((player) => player.userGuid);

    let response = await createTeam(payload);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        teamName + " created successfully",
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      setAddToGroup([]);
      setTeamName("");
      updateLeague();
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_CREATE_TEAM);
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  const handleCheckboxChange = (player: LeagueRosterEntryDto) => {
    if (group.some((item) => item.userGuid === player.userGuid)) {
      setAddToGroup(group.filter((item) => item.userGuid !== player.userGuid));
    } else {
      if (group.length <= teamSize) setAddToGroup([...group, player]);
    }
  };

  const removeSelectedPlayerFromRoster = useCallback(
    async (player: LeagueRosterEntryDto) => {
      let response = await removePlayerFromRoster();

      if (response && isApiStatusSuccess(response?.status)) {
        await showCustomNotification(player?.userName + " removed from roster");
      } else {
        if (!toastIsActive) {
          showCustomNotification(
            getApiResponseErrorMessage(response?.body),
            GenericNotificationType.ERROR
          );
        }
      }
    },
    [removePlayerFromRoster, toastIsActive]
  );

  const teamChangeCallback = useCallback(() => {
    refreshLeagueTeams();
    refreshLeagueRoster();
    updateLeague();
  }, [refreshLeagueRoster, refreshLeagueTeams, updateLeague]);

  function handleRosterSortOnClick(sortProperty: string) {
    setSortProperty(sortProperty);
    setSortAscending(!sortAscending);
  }

  function getTeamNameFromPlayerId(playerId: string) {
    let teamName = "";

    leagueTeams.forEach((team) => {
      team.teamMembers.forEach((member) => {
        if (member.playerId === playerId) {
          teamName = team.name || "";
          return;
        }
      });
    });
    return teamName;
  }

  useEffect(() => {
    if (selectedPlayer) {
      removeSelectedPlayerFromRoster(selectedPlayer);
      setSelectedPlayer(undefined);
      teamChangeCallback();
    }
  }, [selectedPlayer, removeSelectedPlayerFromRoster, teamChangeCallback]);

  const AddPlayerToTeamDropdown: React.FC<AddPlayerToTeamProps> = (props) => {
    const { player } = props;
    const [teamName] = useState<string>("");

    const currentFacilityId = useGetCurrentLocalFacilityId();

    const leagueTeamMembers = leagueTeams.reduce(
      (count, team) => count + team.teamMembers.length,
      0
    );

    const handleAddPlayerToTeam = async (team: ClubhouseRoundTeamDto) => {
      let url = `${process.env.REACT_APP_HTTPS_PROTOCOL}${process.env.REACT_APP_BASE_URL}/api/facility/${currentFacilityId}/league/${selectedLeague?.id}/team/${team.id}`;

      let payload = {} as PatchTeamCommandPayload;
      payload.teamMembers = [player.userGuid];

      await axios
        .patch<ClubhouseRoundTeamDto[]>(url, payload, {
          ...leagueQueryDefaultOptions(),
        })
        .then(() => {
          showCustomNotification(
            `${player.firstName} added to ${teamName}`,
            GenericNotificationType.SUCCESS,
            "🎉"
          );
          teamChangeCallback();
        })
        .catch(() => {
          showCustomNotification(
            `An error occurred adding player to ${teamName}`,
            GenericNotificationType.ERROR
          );
        });
    };

    return (
      <>
        {leagueTeams.length > 0 ? (
          <Tippy
            className={globalStyles.toolTip}
            placement="bottom"
            allowHTML={true}
            appendTo={document.body}
            interactive={true}
            interactiveBorder={15}
            zIndex={9999}
            content={
              <div className="">
                <ul className="z-9999">
                  {leagueTeams.map((team) => (
                    <li
                      key={team.id}
                      className="border-b border-gray-200 px-1 py-2 last:border-none"
                    >
                      {team.teamMembers.length ===
                      selectedLeague.settings.leagueTeamSize ? (
                        <Tippy
                          content={`${team.name} is full`}
                          className={globalStyles.toolTip}
                          placement="right"
                        >
                          <button className="cursor-not-allowed text-gray-400">
                            {team.name}
                          </button>
                        </Tippy>
                      ) : (
                        <button
                          className="cursor-pointer"
                          onClick={() => handleAddPlayerToTeam(team)}
                        >
                          {team.name}
                        </button>
                      )}
                    </li>
                  ))}
                  {(leagueTeamMembers === 0 ||
                    leagueTeamMembers < leagueRoster.length) && (
                    <li className="border-b border-gray-200 px-1 py-2 last:border-none">
                      <button
                        className="cursor-pointer"
                        onClick={() => handleShowGroupCreate()}
                      >
                        Create Team
                      </button>
                    </li>
                  )}
                </ul>
              </div>
            }
          >
            <div className="addToTeam cursor-pointer font-bold inline-block">
              Add to Team
            </div>
          </Tippy>
        ) : (
          <span className="text-gray-400 dark:text-gray-50">
            <button
              className="cursor-pointer"
              onClick={() => handleShowGroupCreate()}
            >
              Create Team
            </button>
          </span>
        )}
      </>
    );
  };

  return (
    <>
      <div className={styles.leagueRosterFormContainer}>
        <div className={`${showTeamView ? "hidden" : null} flex flex-row`}>
          <CreateLeagueRosterSearch />
        </div>
        {showGroup ? (
          <motion.div
            className={styles.leagueGroupsHeader}
            key="leagueGroupsHeader"
          >
            <div className="w-full mb-4">
              <div className="w-full poppins text-gray-500 text-sm h-12 pt-4">
                <button
                  onClick={() => {
                    setShowGroup(false);
                    setAddToGroup([]);
                  }}
                >
                  <BsArrowLeft className="w-5 h-5 mr-1" />
                </button>
                Available players to add to this team (max {teamSize}){" "}
                <span className="font-semibold text-brandDarkGrey pl-2">
                  {group.length}/{teamSize}
                </span>
              </div>
              <div className="flex flex-row w-full h-10">
                <div className="flex w-3/5 poppins">
                  <input
                    className="border-2 border-gray-100 rounded-lg px-4 py-2 w-full text-sm mr-2"
                    placeholder="Enter a team name"
                    onChange={(e: React.FormEvent<HTMLInputElement>) =>
                      handleNameChange(e)
                    }
                    value={teamName}
                  />
                </div>
                <div className="flex w-2/5 poppins">
                  <motion.button
                    className={
                      group.length === teamSize
                        ? `${globalStyles.primaryButton} ${globalStyles.buttonSmall}`
                        : `${globalStyles.tertiaryButton} ${globalStyles.buttonSmall}`
                    }
                    onClick={() => handleCreateNewTeam()}
                    animate={
                      group.length === teamSize
                        ? { scale: [1, 1.2, 1.2, 1, 1] }
                        : { scale: 1 }
                    }
                    key="saveTeamButton"
                  >
                    {createTeamQueryState.isPending ? (
                      <>
                        <CgSpinner className="size-4 animate-spin mr-2" />{" "}
                        Saving Team...
                      </>
                    ) : (
                      "Save Team"
                    )}
                  </motion.button>
                  <button
                    className={`${globalStyles.tertiaryButton} ${globalStyles.buttonSmall} ml-1`}
                    onClick={() => {
                      setShowTeamView(true);
                      setShowGroup(false);
                    }}
                  >
                    Team View
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        ) : (
          <motion.div
            className="flex flex-row-reverse items-right"
            key="leagueTeamsHeader"
          >
            <div className="flex">
              <button
                className="bg-darkCream poppins text-brandDarkGray text-xs shadow-sm rounded-lg py-2 px-4 mr-2 hover:bg-brandGreen hover:text-white"
                onClick={() => setShowTeamView(!showTeamView)}
              >
                {showTeamView ? "Roster View" : "Team View"}
              </button>
              <button
                className="bg-brandYellow text-brandDarkGray text-xs shadow rounded-lg py-2 px-4 poppins hover:bg-brandGreen hover:text-white"
                onClick={handleShowGroupCreate}
              >
                New Team
              </button>
            </div>
          </motion.div>
        )}

        {showTeamView ? (
          <>
            {leagueTeams.length === 0 ? (
              <div className="text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200 text-center h-full">
                <MdClose className="block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200" />
                Teams will appear here after you create one. Click the 'New
                Team' button then select players, set a name and save the team.
              </div>
            ) : (
              <motion.ul
                variants={listItemTopDownShow}
                initial={MotionVariantType.HIDDEN}
                animate={MotionVariantType.SHOW}
                key="leagueTeamsList"
              >
                {leagueTeams?.map((team, index) => (
                  <motion.li layout key={`leagueTeam-${team.id}-${index}`}>
                    <Team
                      team={team}
                      leagueRoster={leagueRoster}
                      leagueTeams={leagueTeams}
                      callback={() => teamChangeCallback()}
                    />
                  </motion.li>
                ))}
              </motion.ul>
            )}
          </>
        ) : leagueRoster && leagueRoster.length > 0 ? (
          <>
            <div className="grid grid-cols-12 gap-3 pt-4 text-xs border-b border-gray-200">
              <div
                className={`${
                  showGroup ? "col-span-1 text-gray-400 text-center" : "hidden"
                }`}
              ></div>
              <div className="hidden sm:block sm:col-span-2 text-gray-400">
                Team
              </div>
              <div className="col-span-8 sm:col-span-4 text-gray-400">
                <button onClick={() => handleRosterSortOnClick("firstName")}>
                  First <FaSort className="h-4 w-4 mr-2" />
                </button>
                <button onClick={() => handleRosterSortOnClick("lastName")}>
                  Last <FaSort className="h-4 w-4" />
                </button>
              </div>
              <div className="hidden sm:block col-span-1"></div>
              <div className="hidden sm:block sm:col-span-3 text-gray-400">
                Email
              </div>

              <div
                className={`${
                  showGroup
                    ? "hidden"
                    : "col-span-2 xs:col-span-1 text-gray-400 text-left"
                }`}
              >
                <button
                  onClick={() => handleRosterSortOnClick("aGHandicapIndex")}
                >
                  HCP <FaSort className="h-4 w-4" />
                </button>
              </div>
              <div className="col-span-1 text-gray-400 text-center"></div>
            </div>
            <div className="text-xs text-gray-300 mt-1 hidden">
              "" indicates no first or last name for this Locker profile.
              Username is used instead.
            </div>
            <motion.div
              variants={dailyQueueContainer}
              initial={MotionVariantType.HIDDEN}
              animate={MotionVariantType.SHOW}
              key={`leagueRosterList_${leagueRoster.length}`}
            >
              {sortedLeagueRoster.length > 0 ? (
                sortedLeagueRoster.map((player, index) => {
                  return (
                    <motion.div
                      animate={{ opacity: 1, x: 0 }}
                      layout
                      variants={dailyQueueListItemVariant}
                      className={`${styles.leagueRosterListItem} ${
                        showGroup ? "" : ""
                      }`}
                      key={`leagueRoster-${player.userGuid}-${index}`}
                    >
                      <div
                        className={`${
                          showGroup ? "col-span-1  text-center" : "hidden"
                        }`}
                        key={index}
                      >
                        {showGroup && (
                          <input
                            type="checkbox"
                            onClick={() => handleCheckboxChange(player)}
                            id={player.userGuid}
                            checked={group.some(
                              (item) => item.userGuid === player.userGuid
                            )}
                            disabled={
                              !group.some(
                                (item) => item.userGuid === player.userGuid
                              ) && group.length > 1
                            }
                            className="border-gray-400 rounded-sm disabled:bg-gray-300 text-brandGreen focus:ring-0 ring-opacity-0 border-2"
                          />
                        )}
                      </div>
                      <div className="hidden sm:block sm:col-span-2 text-ellipsis truncate w-full">
                        <span className="text-gray-300 mr-2">{index + 1}.</span>
                        {getTeamNameFromPlayerId(player.userGuid) ||
                          (!showGroup ? (
                            <AddPlayerToTeamDropdown player={player} />
                          ) : null)}
                      </div>
                      <div className="col-span-8 sm:col-span-4 text-ellipsis truncate w-full">
                        {player?.firstName ? (
                          <>
                            <span
                              className={
                                sortProperty === "firstName"
                                  ? "font-bold mr-1"
                                  : "mr-1"
                              }
                            >
                              {player.firstName}
                            </span>
                            <span
                              className={
                                sortProperty === "lastName" ? "font-bold" : ""
                              }
                            >
                              {player.lastName}
                            </span>
                          </>
                        ) : (
                          <span className="text-gray-500">
                            "{player.userName}"
                          </span>
                        )}
                      </div>
                      <div className="hidden sm:block col-span-1">
                        <span className="bg-gray-200 dark:bg-zinc-900 rounded-md px-2 py-1 mr-2">
                          {Gender[player?.gender].charAt(0)}
                        </span>
                      </div>
                      <div className="hidden sm:block sm:col-span-3 text-ellipsis truncate w-full">
                        {player.userEmail}
                      </div>

                      <div
                        className={`${
                          showGroup
                            ? "hidden"
                            : "col-span-2 sm:col-span-1 self-center"
                        }`}
                      >
                        {player.aGHandicapIndex}
                      </div>
                      <div className="col-span-1 text-right">
                        <button
                          onClick={() => handleRemovePlayerFromRoster(player)}
                          className="ml-2"
                        >
                          <AiOutlineMinusCircle className="size-4 fill-gray-400 text-gray-400 hover:fill-red-500 hover:text-red-500" />
                        </button>
                      </div>
                    </motion.div>
                  );
                })
              ) : (
                <>
                  <div className="text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200 text-center h-full">
                    <MdClose className="block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200" />
                    All players have been added to a team.
                  </div>
                </>
              )}
            </motion.div>
          </>
        ) : (
          <div className="text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200 text-center h-full">
            <MdClose className="block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200" />
            Players will appear here after you add one. Start typing a name in
            the search bar above then click an item from the list to add your
            first player.
          </div>
        )}
      </div>
    </>
  );
};

export default CreateTeamLeagueRoster;
