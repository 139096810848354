import { motion } from "framer-motion";
import {
  GetMatchResponseDto,
  GetMatchResponseDtoParticipant,
} from "MatchPlayModels";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { globalStyles } from "components/globalStyles";
import { styles } from "../teams/RosterAndGroups.styles";
import { useGetSelectedLeague } from "modules/leagues/leagueHooks";
import { useSelectedLeagueRoundId } from "modules/leagues/leagueRoundHooks";
import { useDeleteLeagueRoundMatch } from "modules/leagues/leagueMatchHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import Tippy from "@tippyjs/react";

import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import { isApiStatusSuccess } from "utils/api/apiHelper";

type MatchProps = {
  match: GetMatchResponseDto;
  callback?: any;
};

const Match: React.FC<MatchProps> = (props) => {
  const { match, callback } = props;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();

  const [, deleteMatch] = useDeleteLeagueRoundMatch(
    currentFacilityId,
    selectedLeague.id,
    selectedLeagueRoundId,
    match?.id
  );

  const handleDeleteMatch = () => {
    let response = deleteMatch();

    response?.then((res) => {
      if (isApiStatusSuccess(res?.status)) {
        showCustomNotification(
          "Match deleted successfully",
          GenericNotificationType.SUCCESS
        );
        callback && callback();
      } else {
        showCustomNotification(
          "An error occurred",
          GenericNotificationType.ERROR
        );
      }
    });
  };

  return (
    <motion.div
      className="rounded-lg p-4 bg-white dark:bg-zinc-900/80 border border-gray-200 shadow-md h-auto"
      variants={containerChildMotionVariants}
      key={`group-${match.id}`}
    >
      <div className="flex flex-row">
        <div className=" text-black w-5/6 h-10">
          <div className="flex flex-row">
            <div className="flex w-full poppins font-semibold text-xs text-brandDarkGray dark:text-gray-200">
              {match.name}
            </div>
          </div>
        </div>
        <div className="w-1/6 justify-items-end">
          <Tippy
            content={`Delete ${match.name}`}
            className={`${globalStyles.toolTip}`}
          >
            <button onClick={() => handleDeleteMatch()} className=" ml-1">
              <AiOutlineMinusCircle className="size-4 fill-red-500 text-red-500" />
            </button>
          </Tippy>
        </div>
      </div>
      {match?.participants?.map(
        (participant: GetMatchResponseDtoParticipant, index) => {
          return (
            <div
              className={`${styles.teamPlayerListContainer}`}
              key={`match-${match.id}-${participant.userGuid}-${index}`}
            >
              <div className="col-span-1 text-left text-gray-300">
                {participant?.number}.
              </div>
              {participant?.teamName ? (
                <div className="col-span-8 text-left truncate">
                  {participant?.teamName || ""}
                </div>
              ) : (
                <div className="col-span-8 text-left text-ellipsis truncate">
                  {participant?.firstName
                    ? (participant?.firstName || "") +
                      " " +
                      (participant?.lastName || "")
                    : participant?.userName}
                </div>
              )}
              <div className="col-span-2 text-left text-ellipsis truncate">
                {participant?.groupNumber}
              </div>
              <div className="col-span-1"></div>
            </div>
          );
        }
      )}
    </motion.div>
  );
};
export default Match;
