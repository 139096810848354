import { useState } from "react";
import { SimulatorReservation } from "Models";
import { MotionVariantType } from "types/enums";
import { AnimateSharedLayout, motion } from "framer-motion";
import { dailyQueueContainer } from "utils/animations/animationHelper";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useSimulatorReservationsByFacilityId } from "modules/simulator-reservations/simulatorReservationHooks";
import SimulatorReservationItem from "./SimulatorReservationItem";
import ShowLoadingAnimation from "../dashboard/ShowLoadingAnimation";
import ShowQueueOnDashboardCheckbox from "./ShowQueueOnDashboardCheckbox";
import ShowQueueItemsByDateOption from "./ShowQueueItemsByDateOption";
import ReloadSimulatorReservationsButton from "./ReloadSimulatorReservationsButton";
import { dateTimeRanges } from "modules/simulator-reservations/simulatorReservationHelpers";
import { MdClose } from "react-icons/md";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import {
  LocalStorageItems,
  localStorageValueToBoolean,
} from "utils/hooks/localStorage";
import CreateQueueItemButton from "./CreateQueueItemButton";

const QueueList: React.FC<QueueListProps> = (props) => {
  const currentFacilityId = useGetCurrentLocalFacilityId();
  const [selectedDate, setSelectedDate] = useState(dateTimeRanges.today);
  const [selectedSimulatorReservationId, setSimulatorReservationId] = useState<
    string | boolean
  >(false);
  const [
    facilitySimulatorReservations,
    facilityReservationStatus,
    refreshFacilityReservations,
  ] = useSimulatorReservationsByFacilityId(
    currentFacilityId,
    selectedDate.startTime,
    selectedDate.endTime
  );

  const showQueueOnDashboard = useSelector(
    (state: RootState) => state.nav?.queue?.showOnDashboard
  );

  const [, setListView] = useState(
    localStorage.getItem(LocalStorageItems.DASHBOARDGRIDORLISTVIEW)
      ? localStorageValueToBoolean(
          localStorage.getItem(LocalStorageItems.DASHBOARDGRIDORLISTVIEW)
        )
      : true
  );

  return (
    <nav className="h-full" aria-label="Directory">
      <div className="pr-4">
        <div data-testid="dailyQueueContainer">
          <ShowQueueItemsByDateOption
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
        </div>
        <div className={`py-4 ${props.dashboardView ? "" : null}`}>
          <ShowQueueOnDashboardCheckbox
            labelText={
              props.dashboardView
                ? "Display Queue"
                : "Display Queue on dashboard"
            }
          />
          {!props.modalView && <CreateQueueItemButton />}
          <ReloadSimulatorReservationsButton
            callback={refreshFacilityReservations}
          />
        </div>
        <div className="relative">
          <div className="dailyQueueContainer rounded-md w-full h-full mt-8">
            <AnimateSharedLayout>
              <motion.ul
                className="relative z-0 divide-y divide-gray-200 mb-8 w-full h-full border border-gray-200 dark:border-0 rounded-xl bg-white dark:bg-zinc-800"
                variants={dailyQueueContainer}
                initial={MotionVariantType.HIDDEN}
                animate={MotionVariantType.SHOW}
              >
                {facilitySimulatorReservations?.length ? (
                  facilitySimulatorReservations?.map(
                    (reservation: SimulatorReservation) => (
                      <SimulatorReservationItem
                        simulatorReservation={reservation}
                        key={reservation.id}
                        isOpen={
                          selectedSimulatorReservationId === reservation.id
                        }
                        callback={() =>
                          setSimulatorReservationId(
                            selectedSimulatorReservationId === reservation.id
                              ? false
                              : reservation.id
                          )
                        }
                        highlightItem={props.dashboardView}
                      />
                    )
                  )
                ) : (
                  <motion.li className="text-center py-2 h-30">
                    {facilityReservationStatus.isPending ? (
                      <ShowLoadingAnimation showListView={false} />
                    ) : (
                      <div className=" text-xs text-gray-400 p-8 dark:bg-zinc-800 dark:text-gray-200">
                        <MdClose className="block border border-gray-400 rounded p-1 mb-2 w-6 h-6 text-gray-400 dark:text-gray-200" />
                        There are no current simulator reservations
                      </div>
                    )}
                  </motion.li>
                )}
              </motion.ul>
            </AnimateSharedLayout>
          </div>
          <div className="hidden bottom-1/2 -left-3 w-6 h-6 bg-red border rounded-full text-gray-400 items-center text-center align-center border-gray-200  dark:bg-zinc-800  dark:text-gray-200 dark:border-gray-900">
            <button onClick={() => setListView(!showQueueOnDashboard)}>
              {showQueueOnDashboard ? (
                <FiChevronsRight className="mb-1" />
              ) : (
                <FiChevronsLeft className="mb-1" />
              )}
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
};
export default QueueList;

type QueueListProps = {
  dashboardView?: boolean;
  modalView?: boolean;
  simulatorReservationId?: string;
};

QueueList.defaultProps = {
  dashboardView: false,
  modalView: false,
};
