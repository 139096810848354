import {
  HandicapMode,
  HoleType,
  MatchHoleParticipantResult,
  ScoringType,
} from "types/enums";
import {
  MatchScorecardResults,
  PlayerScorecardDto,
  ScorecardHoleElementDto,
  ScorecardMatchResponseDto,
} from "ScorecardModels";
import { RoundLeaderboardPlayerDto } from "Models";

export const scorecardHoleStylesHelper = (
  score: number,
  par: number
): string => {
  if (score === null || score === 0) return "";

  const holeScore = scoreDifference(score, par);

  return scorecardHoleColorHelper[holeScore];
};

function scoreDifference(score: number, par: number) {
  let diff = score - par;

  if (diff > 2) diff = 2;
  if (diff < -3) diff = -3;

  return diff;
}

export function scorecardFrontAndBackHoles(holes: ScorecardHoleElementDto[]) {
  const frontNineHoles = holes.filter(
    (hole) => hole.holeNumber >= 1 && hole.holeNumber <= 9
  );
  const backNineHoles = holes.filter(
    (hole) => hole.holeNumber >= 10 && hole.holeNumber <= 18
  );
  return [frontNineHoles, backNineHoles];
}

export function convertPlayerLeaderboardToScorecard(
  playerRoundLeaderboard: RoundLeaderboardPlayerDto,
  showNetScore: boolean,
  teamNumber: number
): PlayerScorecardDto {
  return {
    playerName:
      playerRoundLeaderboard?.playerName ||
      playerRoundLeaderboard?.playerDisplayName,
    playerDisplayName: playerRoundLeaderboard?.playerDisplayName,
    playerGuid: playerRoundLeaderboard?.playerGuid || "",
    userId: "",
    teeUsed: 0,
    handicapUsed: showNetScore ? HandicapMode.ABSOLUTE : HandicapMode.OFF,
    gender: playerRoundLeaderboard?.gender,
    aGHandicap: playerRoundLeaderboard?.agHandicap || 0,
    partOfLeague: playerRoundLeaderboard?.partOfLeague,
    partOfRound: playerRoundLeaderboard?.partOfRound,
    holes:
      playerRoundLeaderboard?.holes.map((hole) => ({
        holeNumber: hole.holeNumber,
        par: hole.par,
        selected: hole.selected,
        handicapIndex: hole.handicapIndex,
        gross: hole.gross,
        net: hole.net,
        conceded: hole.conceded,
        operatorAdjustedScore: hole.operatorAdjustedScore,
      })) || [],
    gross: playerRoundLeaderboard?.gross || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
    net: playerRoundLeaderboard?.net || {
      total: 0,
      in: 0,
      out: 0,
      strokesOverPar: 0,
      strokesOverParDetail: "",
    },
    team: teamNumber,
  };
}

export function createMatchResultHolesWon(
  matchResults: ScorecardMatchResponseDto,
  showNetScore: boolean
): MatchScorecardResults[] {
  let holesWon: MatchScorecardResults[] = [];

  Object.keys(
    showNetScore ? matchResults?.netHoleWinner : matchResults?.grossHoleWinner
  ).forEach((key) => {
    holesWon.push({
      holeNumber: Number(key),
      holeResult: showNetScore
        ? matchResults?.netHoleWinner[key].winningParticipant
        : matchResults?.grossHoleWinner[key].winningParticipant,
      runningMatchWinner: showNetScore
        ? matchResults?.netHoleWinner[key].winningParticipant
        : matchResults?.grossHoleWinner[key].winningParticipant,
      runningResult: showNetScore
        ? matchResults?.netHoleWinner[key].runningResult
        : matchResults?.grossHoleWinner[key].runningResult,
    });
  });

  return holesWon;
}

export const scorecardHoleColorHelper = {
  [HoleType.ALBATROSS.toString()]: "albatross w-6 h-6 rounded-full",
  [HoleType.EAGLE.toString()]: "eagle w-6 h-6 rounded-full",
  [HoleType.BIRDIE.toString()]: "birdie w-6 h-6 rounded-full",
  [HoleType.PAR.toString()]: "w-6 h-6 ",
  [HoleType.BOGEY.toString()]: "bogey w-6 h-6",
  [HoleType.DOUBLE_BOGEY.toString()]: "doubleBogey w-6 h-6",
};

export function eighteenInScore(totalScore: number, frontNineScore: number) {
  return frontNineScore && frontNineScore > 0
    ? frontNineScore - totalScore
    : totalScore;
}

export const isRoundMatchPlay = (scoringType: ScoringType) => {
  return scoringType === ScoringType.MATCH;
};

export const holeNumberMatchPlayHelper = {
  [MatchHoleParticipantResult.TIED.toString()]: "",
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "size-4 rounded-full bg-brandGreen text-white",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "size-4 bg-brandYellow text-black",
};

export const runningResultWinnerHelper = {
  [MatchHoleParticipantResult.TIED.toString()]: "text-brandDarkGreen text-[10px] font-normal",
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "text-brandGreen text-[10px] font-normal",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "text-brandYellow text-[10px] font-normal",
};

export const teamColorHelper = {
  [MatchHoleParticipantResult.PARTICIPANT_1.toString()]: "rounded-full bg-brandGreen",
  [MatchHoleParticipantResult.PARTICIPANT_2.toString()]: "bg-brandYellow",
};
