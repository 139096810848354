import { useState } from "react";
import { useAppDispatch } from "utils/hooks";
import { ModalType } from "utils/modals/modalHelpers";
import { modalAction } from "ui-modules/modals/modalSlice";
import Row from "components/ui/layout/Row";
import Modal from "components/shared/Modal";
import QueueItemContainer from "./QueueItemContainer";
import SimulatorReservationList from "components/control/simulator-reservations/QueueList";

const QueueModal: React.FC<LeagueRoundGroupModalProps> = (props) => {
  const dispatch = useAppDispatch();
  const [createReservation, setCreateReservation] = useState(false);

  const handleCloseModal = async () => {
    dispatch(
      modalAction({
        isOpen: false,
        modalName: ModalType.QueueModal,
      })
    );
  };

  return (
    <Modal
      modalName={props.modalName}
      closeModalCallback={handleCloseModal}
      hideHeader={false}
      title={`Load Reservation` || props.title}
      hideFooter={true}
      modalSize="w-full h-full md:w-1/2 2xl:w-1/2"
      body={
        <Row cssClasses="px-8 pb-4">
          <div className="flex flex-row pb-4">
            <button
              className="text-xs px-4 dark:text-gray-50"
              onClick={() => setCreateReservation(false)}
            >
              View
            </button>
            <button
              className="text-xs px-4 dark:text-gray-50"
              onClick={() => setCreateReservation(true)}
            >
              Create
            </button>
          </div>

          {createReservation ? (
            <QueueItemContainer />
          ) : (
            <SimulatorReservationList modalView={true} />
          )}
        </Row>
      }
    />
  );
};

type LeagueRoundGroupModalProps = {
  title?: string;
  modalName: string;
};

QueueModal.defaultProps = {
  title: "Round Presets",
};

export default QueueModal;
