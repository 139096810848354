import { useState } from "react";
import { motion } from "framer-motion";
import { BsPeople, BsSave2 } from "react-icons/bs";
import { useSelector } from "react-redux";
import { BsCloudUpload } from "react-icons/bs";
import { AiOutlineMinusCircle } from "react-icons/ai";
import { globalStyles } from "components/globalStyles";
import { styles } from "../teams/RosterAndGroups.styles";
import {
  useAssignGroupToSim,
  useCreateTeamLeagueGroup,
  useLeagueGroupByFacilityLeagueRoundId,
} from "modules/leagues/leagueGroupHooks";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { containerChildMotionVariants } from "utils/animations/animationHelper";
import {
  isApiStatusSuccess,
  getApiResponseErrorMessage,
} from "utils/api/apiHelper";
import { sendGroupToSim } from "modules/leagues/leagueGroupQueries";
import {
  ClubhouseEvents,
  pendoTrackBasicEvent,
} from "utils/pendo/pendoHelpers";
import { useLeagueRosterByLeagueId } from "modules/leagues/leagueRosterHooks";
import { getSimulatorsByCurrentFacility } from "modules/simulator/simulatorSelectors";
import {
  showCustomNotification,
  GenericNotificationType,
} from "utils/notifications/notificationHelpers";
import {
  ClubhouseLeagueRoundPlayerDto,
  ClubhouseRoundGroupSimulatorDto,
  LeagueRosterEntryDto,
  SendGroupsToSimPayload,
} from "Models";
import {
  useGetSelectedLeague,
  useIsSynchronousMatchLeague,
  useSelectedLeagueRoundId,
} from "modules/leagues/leagueHooks";
import Select from "react-select";
import toast from "react-hot-toast";
import Tippy from "@tippyjs/react";
import { FaPlusCircle } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";

type GroupProps = {
  group: ClubhouseLeagueRoundPlayerDto[];
};

const TeamGroup: React.FC<GroupProps> = (props) => {
  const { group } = props;

  const groupNumber = group[0].groupNumber;

  const currentFacilityId = useGetCurrentLocalFacilityId();
  const selectedLeague = useGetSelectedLeague();
  const selectedLeagueRoundId = useSelectedLeagueRoundId();
  const isSyncMatchLeague = useIsSynchronousMatchLeague();
  const simulators = useSelector(getSimulatorsByCurrentFacility);
  const selectedSimulatorId = group[0]?.simulatorId;

  const [selectedSim, setSelectedSim] = useState<{
    label: string;
    value: string;
  }>({ label: selectedSimulatorId || "", value: selectedSimulatorId || "" });

  const [leagueRoster] = useLeagueRosterByLeagueId(
    currentFacilityId,
    selectedLeague?.id
  );

  const [leagueGroups] = useLeagueGroupByFacilityLeagueRoundId(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [assignGroupQueryState, assignGroupToSim] = useAssignGroupToSim(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const [, updateTeamLeagueGroup] = useCreateTeamLeagueGroup(
    currentFacilityId,
    selectedLeague?.id,
    selectedLeagueRoundId
  );

  const handleSaveGroupClick = async () => {
    let payload = [] as ClubhouseRoundGroupSimulatorDto[];

    payload.push({ simulatorId: selectedSim?.value, groupNumber: groupNumber });

    let response = await assignGroupToSim(payload);

    if (response && isApiStatusSuccess(response?.status)) {
      toast("Sim updated successfully", {
        position: "bottom-left",
        icon: "🎉",
        duration: 2000,
      });
      toast(`${selectedSim.label} has been assigned to Group ${groupNumber}`, {
        position: "bottom-left",
        duration: 3000,
      });
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_ASSIGN_GROUP_TO_SIM);
    } else {
      if (response) {
        showCustomNotification(
          getApiResponseErrorMessage(response?.body),
          GenericNotificationType.ERROR
        );
      }
    }
  };

  const handleSendGroupToSimClick = async () => {
    let payload = [] as SendGroupsToSimPayload[];

    payload.push({ simulatorId: selectedSim?.value, groupNumber: groupNumber });

    let response = await sendGroupToSim(
      currentFacilityId,
      selectedLeague?.id,
      selectedLeagueRoundId,
      payload
    );

    if (response && isApiStatusSuccess(response?.status)) {
      await toast("Group has been sent to sim", {
        position: "bottom-left",
        icon: "🎉",
        duration: 2000,
      });
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_SEND_GROUPS_TO_SIM);
    } else {
      if (response) {
        await toast(getApiResponseErrorMessage(response.error.data), {
          position: "bottom-left",
          icon: "⛔️",
          duration: 5000,
          className: "w-1/2 md:w-1/3 lg:w-1/4",
        });
      }
    }
  };

  const handleRemovePlayerFromGroup = async (
    player: ClubhouseLeagueRoundPlayerDto
  ) => {
    if (
      window.confirm(
        `Do you want to remove ${player.firstName} from Group ${player.groupNumber}?`
      )
    ) {
      let response = await updateTeamLeagueGroup([
        {
          teamId: player?.teamId || "",
          teamPlayersUserGuids: [player.playerId],
        },
      ]);

      if (response && isApiStatusSuccess(response?.status)) {
        showCustomNotification(
          `${player.firstName} removed from ${groupNumber}`,
          GenericNotificationType.SUCCESS,
          "🎉"
        );
        pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
      } else {
        showCustomNotification(
          getApiResponseErrorMessage(response?.body),
          GenericNotificationType.ERROR
        );
      }
    }
  };

  const handleAddPlayerToGroup = async (
    player: LeagueRosterEntryDto,
    groupNumber: number,
    teamId: string
  ) => {
    let response = await updateTeamLeagueGroup([
      {
        teamPlayersUserGuids: [player.userGuid],
        groupNumber: groupNumber,
        teamId: teamId,
      },
    ]);

    if (response && isApiStatusSuccess(response?.status)) {
      showCustomNotification(
        player.firstName + " added to Group " + groupNumber,
        GenericNotificationType.SUCCESS,
        "🎉"
      );
      pendoTrackBasicEvent(ClubhouseEvents.LEAGUE_ROUND_CREATE_GROUP);
    } else {
      showCustomNotification(
        getApiResponseErrorMessage(response?.body),
        GenericNotificationType.ERROR
      );
    }
  };

  function getGroupNumberFromPlayerId(playerId: string): number | undefined {
    let groupNumber;
    leagueGroups?.forEach((groupMember) => {
      if (groupMember.playerId === playerId) {
        return (groupNumber = groupMember.groupNumber);
      }
    });
    return groupNumber;
  }

  return (
    <motion.div
      className="rounded-lg p-4 bg-white dark:bg-zinc-900/80 border border-gray-200 shadow-md h-auto"
      variants={containerChildMotionVariants}
      key={`group-${groupNumber}`}
    >
      <div className="flex flex-row w-full m-0">
        <div className="w-full text-black h-10">
          <div className="flex w-full poppins font-bold text-brandDarkGray dark:text-gray-200">
            TeamGroup {groupNumber}
          </div>
        </div>
        <div className="w-12 justify-items-end h-10">
          <div
            className={`${
              group?.length === selectedLeague?.settings?.defaultGroupSize
                ? "bg-brandGreen text-white"
                : "bg-brandYellow text-black"
            }  px-1 py-0.5 mt-1 rounded text-center text-[10px]`}
          >
            <BsPeople className="size-3 mr-0.5" /> {group.length}/
            {selectedLeague?.settings?.defaultGroupSize || 4}
          </div>
        </div>
      </div>
      {group.map((teamMember: ClubhouseLeagueRoundPlayerDto, index) => {
        return (
          <div
            className={`${styles.teamPlayerListContainer}`}
            key={`group-${groupNumber}-${teamMember.playerId}-${index}`}
          >
            <div className="col-span-1 text-left text-gray-300">
              {index + 1}.
            </div>
            <div className="col-span-4 text-left text-ellipsis truncate">
              {teamMember?.firstName
                ? teamMember.firstName + " " + teamMember?.lastName
                : teamMember?.userName}
            </div>
            <div className="col-span-4 text-left truncate">
              {teamMember?.teamName || ""}
            </div>
            <div className="col-span-2 text-left text-ellipsis truncate">
              {teamMember?.agHandicap}
            </div>
            <div className="col-span-1">
              {!isSyncMatchLeague && (
                <Tippy
                  content={`Remove ${teamMember?.firstName} from Group ${groupNumber}`}
                  className={globalStyles.toolTip}
                >
                  <button
                    onClick={() => handleRemovePlayerFromGroup(teamMember)}
                    className=""
                  >
                    <AiOutlineMinusCircle className="size-4 fill-red-500 text-red-500" />
                  </button>
                </Tippy>
              )}
            </div>
          </div>
        );
      })}
      <div>
        {group?.length !== selectedLeague?.settings?.defaultGroupSize &&
          leagueRoster?.length > 0 && (
            <Tippy
              className={globalStyles.toolTip}
              placement="bottom-start"
              arrow={true}
              allowHTML={true}
              appendTo={document.body}
              interactive={true}
              interactiveBorder={5}
              zIndex={9999}
              content={
                <div className="">
                  <ul className="z-9999">
                    {leagueRoster.map(
                      (player) =>
                        !getGroupNumberFromPlayerId(player.userGuid) &&
                        player?.teamId && (
                          <li
                            key={player.userGuid}
                            className="border-b border-gray-200 px-1 py-2 last:border-none"
                          >
                            <button
                              className="cursor-pointer"
                              onClick={() =>
                                handleAddPlayerToGroup(
                                  player,
                                  groupNumber || 0,
                                  player?.teamId?.toString() || ""
                                )
                              }
                            >
                              {player?.firstName} {player?.lastName}
                            </button>
                          </li>
                        )
                    )}
                  </ul>
                </div>
              }
            >
              <div className="text-xs text-gray-400 border-b border-gray-200 px-1 mt-4 last:border-none">
                <FaPlusCircle className="size-3 mr-1 text-brandGreen" /> Add
                player to group
              </div>
            </Tippy>
          )}
      </div>
      <div className={`w-full my-4`}>
        <div className="w-full">
          <Select
            options={simulators.map((simulator) => {
              return {
                label: simulator.name,
                value: simulator.id,
              };
            })}
            value={simulators
              .filter((simulator) => simulator.id === selectedSim.value)
              .map((simulator) => {
                return {
                  label: simulator.name.toString(),
                  value: simulator.id,
                };
              })}
            onChange={(e: { label: string; value: string }) =>
              setSelectedSim({ value: e.value, label: e.label })
            }
            defaultValue={selectedSim}
            labelledBy="Sims"
            selectAllLabel="Select sim to send round to"
            className="text-xs text-left"
            menuPlacement="auto"
            placeholder="Assign group to a sim"
          />
        </div>
      </div>
      <div className={`flex flex-row w-full`}>
        <div className="flex w-1/2 pr-2">
          <button
            className={styles.leagueRosterActionButton}
            onClick={() => handleSaveGroupClick()}
          >
            {assignGroupQueryState?.isPending ? (
              <>
                <CgSpinner className="size-4 animate-spin mr-2" />
                Saving <span className="hidden lg:inline">Sim</span>
              </>
            ) : (
              <>
                <BsSave2 className="size-3 md:size-4 mr-1" />
                Save <span className="hidden lg:inline">Sim</span>
              </>
            )}
          </button>
        </div>
        <div className="flex w-1/2">
          <button
            className={styles.leagueRosterActionButton}
            onClick={() => handleSendGroupToSimClick()}
          >
            <BsCloudUpload className="size-3 md:size-4 mr-1" />
            Send <span className="hidden lg:inline">To Sim</span>
          </button>
        </div>
      </div>
    </motion.div>
  );
};
export default TeamGroup;
