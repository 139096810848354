export const styles = {
  label: "text-sm text-gray-500 dark:text-gray-200 float-left py-1",
  checkbox:
    "text-gray-600 rounded focus:ring-0 ring-opacity-0 border-2 border-gray-500 text-xl mr-2 mb-1",
  createButton:
    "float-right bg-gray-600 hover:bg-green-600 text-white text-xs w-6 h-6 rounded shadow-md hover:animate-pulse",
  refreshButton:
    "float-right bg-gray-600 hover:bg-green-600 text-white text-xs w-6 h-6 rounded shadow-md hover:animate-pulse mr-1",
  dateContainer: "grid grid-cols-2 gap-2 pt-4",
  dateSpan: "text-md font-bold text-gray-600 dark:text-gray-200 pt-4",
  datePicker:
    "dark:text-white dark:bg-zinc-800 w-full border-1 border-brandLightGrey rounded-full text-xs disabled:cursor-not-allowed disabled:bg-gray-100",
  formRowLabel: "text-md font-normal text-gray-800 dark:text-gray-200",
  inputClassName:
    "block w-full px-3 py-2 border-2 border-gray-200 rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue-400 focus:border-blue-400 sm:text-sm bg-white valid:border-green-500 disabled:cursor-not-allowed disabled:bg-gray-100",
  overlay:
    "bg-gray-600 bg-opacity-30 px-5 fixed h-full w-full flex items-center justify-center top-0 left-0 z-9998",
  trayContainer:
    "fixed bg-white dark:bg-zinc-800 text-gray-500 dark:text-gray-200 drop-shadow-xl top-0 right-0 w-full max-w-2xl h-screen z-9999 overflow-y-scroll text-sm border-l border-gray-400 dark:border-zinc-900 ",
  trayHeaderContainer:
    "h-full flex flex-col bg-white dark:bg-zinc-900 shadow-xl overflow-y-scroll border-t-4",
  trayHeader:
    "text-sm md:text-xl font-bold poppins pb-4 inline brand-dark-gray dark:text-gray-200 dark:text-gray-200",
  trayHeaderInnerContainer: "flex items-start justify-between",
  title: "text-lg font-semibold brand-dark-gray dark:text-gray-200 py-5",
  button: "text-white bg-brandGreen rounded text-xs px-2 py-1 poppins",
  helpDocText: "mt-6 relative flex-1 px-8 text-sm",
  headerContainer:
    "grid grid-cols-5 items-center w-full h-16 border-b border-gray-300 dark:border-zinc-900 bg-white dark:bg-zinc-800  shadow-sm px-4 md:px-8",
  headerCloseButton: "text-left pl-2 md:pl-0 align-baseline",
  headerTitle: "col-span-3 text-center",
  headerRightButton: "text-right align-baseline",
  innerContainer: "px-4 md:px-8",
  eventListItem:
    "my-2 bg-transparent hover:drop-shadow-lg hover:border-gray-300 dark:border-zinc-900  border-0 hover:border-2 hover:cursor-pointer w-full",
  eventListItemHighlight: "my-2 hover:cursor-pointer w-full rounded-lg ",
  eventContainer: "px-4 py-4 sm:px-6 flex flex-row",
  eventContainerTopRow: "flex items-center justify-between",
  eventContainerBottomRow: "ml-2 flex-shrink-0 flex",
  eventTime: "text-xs brand-dark-gray font-bold",
  eventName: "inline-flex text-xs leading-5 font-bold brand-dark-gray poppins",
  eventPlayers: "flex items-center text-xs text-gray-400",
  eventOpenItem:
    "mt-2 flex text-xs text-brandDarkGreen sm:mt-0 text-right justify-end align-middle",
  eventSelectDayOptionContainer: "flex items-center mb-4",
  eventSelectDayOptionInnerContainer:
    "flex items-center rounded-md md:items-stretch w-full",
  eventSelectDayOptionLeftButton:
    "flex items-center justify-center rounded-l-md border border-r-0 py-1.5 pl-3 pr-4 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  eventSelectDayOptionRightButton:
    "flex items-center justify-center rounded-r-md border border-l-0 py-1.5 pl-4 pr-3 focus:relative md:w-1/2 md:px-2 md:hover:bg-gray-50 text-xs",
  eventSelectDayOptionSpan: "relative -mx-px h-9 w-px bg-gray-300",
  queueActiveDay:
    "text-black dark:text-gray-200, bg-transparent  dark:bg-zinc-800 ",
  queueInActiveDay: "text-gray-400 dark:text-gray-400 bg-gray-50",
  queuePreviewRightColumn: "text-right text-gray-600 py-1 capitalize",
  queuePreviewLeftColumn: "text-left brand-dark-gray font-bold",
  eventRoundListItem:
    "font-semibold text-md text-gray-600 dark:text-gray-200 p-2 mb-1 rounded cursor-pointer",
  eventRoundListItemErrors:
    "bg-red-500 border-2 border-red-500 text-red-500 font-bold",
  eventRoundListItemIcon:
    "font-semibold text-lg text-gray-600 dark:text-gray-200 w-auto text-right",
  invalidInstallIdContainer: [
    "absolute h-full w-full text-white font-semibold flex flex-col justify-center items-center rounded top-0 left-0",
  ].join(" "),
  invalidInstallIdOverlay: [
    "absolute h-full w-full bg-gray-700 opacity-75 rounded top-0 left-0 z-999",
  ].join(" "),
  invalidInstallIdContent: ["z-999 text-center text-shadow p-8 text-lg"].join(
    " "
  ),
  invalidInstallIdIcon: [
    "fas fa-exclamation-triangle block text-red-500 text-3xl text-md",
  ].join(" "),
  invalidInstallIdButton: [
    "bg-green-500 border-2 rounded px-2 py-1 mt-4 border-gray-400 opacity-100 text-sm shadow-md",
  ].join(" "),
  scorecardNav: "h-full overflow-y-hidden overflow-x-hidden",
  scorecardTable: "table-auto w-full text-xs",
  scorecardLayoutContainer: "rounded py-4",
  scorecardLayoutHeaderRow:
    "flex flex-row w-full poppins text-black px-3 py-3 border-t border-gray-200",
  scorecardLayoutColumnStart:
    "flex flex-none hidden sm:inline-block w-32 font-bold border-r border-gray-300",
  scorecardLayoutColumn: "flex flex-1 justify-center text-center font-bold",
  scorecardLayoutColumnEnd:
    "flex-0 w-8 text-right font-bold border-l border-gray-300",
  scorecardLayoutParRow:
    "flex flex-row w-full poppins text-black px-3 py-3 border-t border-gray-200",
  scorecardLayoutParColumn:
    "flex flex-1 justify-center text-center text-gray-400 dark:text-gray-100",
  scorecardLayoutPlayerColumn: "flex flex-1 justify-center text-gray-400",
  scorecardLayoutPlayerRow:
    "flex flex-row w-full font-normal poppins text-black px-3 py-2 border-t border-gray-200",
  scorecardLayoutPlayerName:
    "flex flex-none hidden sm:inline-block w-32 items-center border-r border-gray-300 truncate",
  scorecardLayoutPlayerRowEnd:
    "flex-0 w-8 text-right right-0 border-l border-gray-300",
  scorecardSearchButton:
    "bg-brandGreen hover:bg-brandYellow text-white hover:text-brandDarkGrey font-semibold rounded-full ml-2 px-2.5 shadow w-full h-auto disabled:opacity-50 disabled:cursor-not-allowed",
  scorecardPrintButton:
    "background-green hover:bg-brandDarkGrey text-white font-semibold rounded shadow border border-gray-300 disabled:opacity-50 disabled:cursor-not-allowed w-16 px-2 py-1 float-right",
  addToLeaderboardButton:
    "poppins bg-brandYellow hover:bg-brandGreen hover:text-white text-black rounded-full shadow-md hover:shadow-none disabled:opacity-50 disabled:cursor-not-allowed px-4 py-2",
  scorecardViewDetails:
    "bg-darkCream border rounded shadow border-brandDarkGreen text-black px-2 py-0.5 hover:text-white hover:bg-gray-400",
  scorecardViewDetailsHeader: "mt-2 sm:flex sm:justify-between",
  showScorecardButton:
    "poppins bg-darkCream text-brandGreen rounded-full px-3 py-1.5 shadow-sm hover:bg-brandGreen hover:text-darkCream text-xs mt-2",
  scorecardList:
    "flex list-none flex-wrap flex-row mt-4 mb-4 pb-2 text-sm font-normal",
  scorecardListItemHeaderContainer: "px-4 py-4 sm:px-6 flex flex-row",
  scorecardListItemHeaderColumnOne: "flex flex-col w-3/4",
  scorecardListPlayerName: "inline-flex text-xs",
  scorecardListItemHeaderInnerContainer: "flex flex-row pb-4",
  scorecardListItemTeamNameColumn: "flex justify-between items-left w-3/4",
  scorecardlistItemFormatRow: "sm:flex w-full text-xs",
  scorecardListItemHeaderColumnTwo: "flex flex-col w-1/4",
  scorecardListItemWinner: "poppins rounded-full px-3 py-1.5 shadow-sm text-xs",
  scorecardListItemBottomRow: "flex m-0 justify-end align-middle text-right",
};
