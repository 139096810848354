import { useState } from "react";
import { BsPeople } from "react-icons/bs";

import { useAppDispatch } from "utils/hooks";
import { FaPeopleArrows } from "react-icons/fa";
import { styles } from "../teams/RosterAndGroups.styles";
import {
  useIsMatchLeague,
  useSelectedLeagueTypeIsTeam,
} from "modules/leagues/leagueHooks";
import { IoPeopleCircle, IoPersonCircle } from "react-icons/io5";
import { setSelectedRoundId } from "modules/leagues/leagueSlice";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import {
  useLeagueRoundsByLeagueId,
  useSelectedLeagueId,
  useSelectedLeagueRound,
} from "modules/leagues/leagueRoundHooks";
import Groups from "./Groups";
import Matches from "./Matches";
import Tippy from "@tippyjs/react";
import GroupsRoster from "./GroupsRoster";
import TeamGroupsRoster from "./TeamGroupsRoster";
import { FiChevronDown } from "react-icons/fi";
import ReactTooltip from "react-tooltip";

const CreateGroupForm = () => {
  const dispatch = useAppDispatch();
  const selectedLeagueRound = useSelectedLeagueRound();
  const isMatchPlayLeague = useIsMatchLeague();
  const isTeamLeague = useSelectedLeagueTypeIsTeam();
  const leagueId = useSelectedLeagueId();
  const currentFacilityId = useGetCurrentLocalFacilityId();
  enum viewOptions {
    MATCH = "Match",
    ROSTER = "Roster",
    GROUP = "Group",
  }

  const [leagueRounds] = useLeagueRoundsByLeagueId(currentFacilityId, leagueId);

  const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
  const [roundGroupView, setRoundGroupView] = useState<viewOptions>(
    viewOptions.ROSTER
  );

  const ShowRoundGroupsDropdown = () => {
    const handleViewRoundsClick = async (leagueRoundId: string) => {
      await dispatch(
        setSelectedRoundId({
          selectedLeagueRoundId: leagueRoundId,
        })
      );
    };

    return (
      <Tippy
        className="poppins text-black text-xs bg-gray-100 dark:bg-zinc-800 dark:text-gray-50 left-0 top-2 rounded float-right inline"
        placement="bottom"
        allowHTML={true}
        appendTo={document.body}
        interactive={true}
        interactiveBorder={5}
        zIndex={9999}
        content={
          <ul className="z-9999 p-2 border-2 rounded-xl">
            {leagueRounds.map((round) => (
              <li
                key={round.id}
                className="border-b border-gray-200 px-1 py-2 last:border-none"
              >
                <button
                  className="cursor-pointer"
                  onClick={() => {
                    handleViewRoundsClick(round.id);
                  }}
                >
                  {round?.name}
                </button>
              </li>
            ))}
          </ul>
        }
      >
        <div className="ml-2 rounded-xl border inline px-2 py-0.5 border-gray-200 hover:bg-gray-100 hover:border-gray-400 cursor-pointer">
          <FiChevronDown className="size-5 inline" />
        </div>
      </Tippy>
    );
  };

  return (
    <>
      <div className={styles.leagueGroupFormContainer}>
        {showCreateGroup ? (
          <div className={styles.addGroupContainer}>
            <div className="flex"></div>
            <div className="flex w-1/2 poppins font-bold text-black dark:text-gray-50 text-left">
              Add players to groups (max 4)
            </div>
            <div className="flex w-1/2 poppins text-black text-right justify-end">
              <button
                className="bg-gray-200 text-brandDarkGray text-xs rounded-lg py-2 px-4 poppins mr-2"
                onClick={() => {
                  setShowCreateGroup(!showCreateGroup);
                  //setshowGroupView(!showGroupView);
                }}
              >
                <BsPeople className="size-4 font-bold" />
                <span className="hidden lg:inline">
                  {roundGroupView === viewOptions.ROSTER && "View Roster"}
                  {roundGroupView === viewOptions.GROUP && "View Group"}
                  {roundGroupView === viewOptions.MATCH && "View Match"}
                </span>
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.addGroupContainer}>
            <div className="flex"></div>
            <div className="flex flex-col md:flex-row w-2/3 lg:w-1/2 poppins text-sm text-black dark:text-gray-200 text-left leading-5">
              <div className="flex w-full md:w-1/2 items-center">
                {selectedLeagueRound?.name}{" "}
                {roundGroupView === viewOptions.ROSTER && "Roster"}
                {roundGroupView === viewOptions.GROUP && "Groups"}
                {roundGroupView === viewOptions.MATCH && "Matches"}
                <ShowRoundGroupsDropdown />
              </div>
              <div className="flex w-full md:w-1/2 "></div>
            </div>
            <div className="flex w-1/3 lg:w-1/2 poppins text-black text-right justify-end">
              <div className={styles.modalViewToggleContainer}>
                <button
                  className={
                    roundGroupView === viewOptions.ROSTER
                      ? styles.modalViewToggleButtonActive
                      : styles.modalViewToggleButtonInactive
                  }
                  onClick={() => setRoundGroupView(viewOptions.ROSTER)}
                  data-tip="View Roster"
                  data-for={`viewOptionsToggle`}
                >
                  <IoPersonCircle
                    className={`size-5 inline ${
                      roundGroupView === viewOptions.ROSTER
                        ? "text-white"
                        : "text-gray-400"
                    }   lg:hidden font-bold`}
                  />
                  <span className="hidden lg:inline">Roster</span>
                </button>

                <button
                  className={
                    roundGroupView === viewOptions.GROUP
                      ? styles.modalViewToggleButtonActive
                      : styles.modalViewToggleButtonInactive
                  }
                  onClick={() => setRoundGroupView(viewOptions.GROUP)}
                  data-tip="View Groups"
                  data-for={`viewOptionsToggle`}
                >
                  <IoPeopleCircle
                    className={`size-5 inline ${
                      roundGroupView === viewOptions.GROUP
                        ? "text-white"
                        : "text-gray-400"
                    }   lg:hidden font-bold`}
                  />
                  <span className="hidden lg:inline">Groups</span>
                </button>
                {isMatchPlayLeague && (
                  <button
                    className={
                      roundGroupView === viewOptions.MATCH
                        ? styles.modalViewToggleButtonActive
                        : styles.modalViewToggleButtonInactive
                    }
                    onClick={() => setRoundGroupView(viewOptions.MATCH)}
                    data-tip="View Matches"
                    data-for={`viewOptionsToggle`}
                  >
                    <FaPeopleArrows
                      className={`size-5 inline ${
                        roundGroupView === viewOptions.MATCH
                          ? "text-white"
                          : "text-gray-400"
                      }  lg:hidden font-bold`}
                    />
                    <span className="hidden lg:inline">Matches</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {roundGroupView === viewOptions.GROUP ? <Groups /> : null}
        {roundGroupView === viewOptions.ROSTER ? (
          isTeamLeague ? (
            <TeamGroupsRoster />
          ) : (
            <GroupsRoster />
          )
        ) : null}
        {roundGroupView === viewOptions.MATCH ? <Matches /> : null}
      </div>
      <ReactTooltip
        id={`viewOptionsToggle`}
        place="bottom"
        delayShow={200}
        backgroundColor="#f9fafb"
        textColor="#4b5563"
        border={true}
        borderColor="#d1d5db"
        multiline={true}
      />
    </>
  );
};

export default CreateGroupForm;
