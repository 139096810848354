import { useSelector } from "react-redux";
import { RootState } from "StoreTypes";
import { queueTrayAction } from "ui-modules/nav/navSlice";
import { useAppDispatch } from "utils/hooks";
import { AnimatePresence, motion } from "framer-motion";
import { styles } from "components/shared/helpdocs/HelpDocTray.styles";
import SimulatorReservationList from "components/control/simulator-reservations/QueueList";
import CreateQueueItemTray from "components/control/simulator-reservations/CreateQueueItemTray";

const QueueTray: React.FC<SlideOverProps> = (props) => {
  const dispatch = useAppDispatch();

  const isSimulatorReservationTrayOpen = useSelector(
    (state: RootState) => state.nav?.queueTray?.isOpen
  );

  const setSimulatorReservationTray = () =>
    dispatch(
      queueTrayAction({
        isOpen: !isSimulatorReservationTrayOpen,
      })
    );

  return (
    <>
      <AnimatePresence>
        {isSimulatorReservationTrayOpen && (
          <>
            <motion.div
              initial={{ x: "100%" }}
              animate={{
                x: 0,
              }}
              exit={{
                x: "100%",
              }}
              transition={{ type: "spring", bounce: 0, duration: 0.4 }}
              className="fixed bg-gray-50 text-gray-500 shadow-lg top-0 right-0 w-full max-w-xl h-screen z-9999 overflow-y-scroll text-sm overflow-x-hidden"
              key="SimulatorReservationTray"
              data-testid="SimulatorReservationTray"
              id="SimulatorReservationTray"
            >
              <div className={styles.headerContainer}>
                <div className={styles.headerCloseButton}>
                  <button
                    onClick={() => setSimulatorReservationTray()}
                    className={styles.button}
                  >
                    Close
                  </button>
                </div>
                <div className={styles.headerTitle}>
                  <h2 className={styles.trayHeader}>Queue Tray</h2>
                </div>
                <div className={styles.headerRightButton}></div>
              </div>
              <div className={`${styles.innerContainer} mt-8 ml-4`}>
                <CreateQueueItemTray />
                <SimulatorReservationList />
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              id="reservationTrayOverlay"
              transition={{ type: "spring", bounce: 0, duration: 0.2 }}
              onClick={() => setSimulatorReservationTray()}
              className={styles.overlay}
            />
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default QueueTray;

type SlideOverProps = {
  title?: string;
  body?: React.ReactNode;
};
