import { useState } from "react";
import { DateTime } from "luxon";
import { PlayerUser } from "Models";
import { styles } from "./Scorecard.styles";
import { useAppDispatch } from "utils/hooks";
import { updateEntities } from "redux-query";
import { IoSearchOutline } from "react-icons/io5";
import { scorcardSearchStyle } from "utils/ui/uiHelpers";
import { ScorecardPayload } from "ScorecardModels";
import { userSearch } from "utils/userSearch/userSearchHelpers";
import { useGetCurrentLocalFacilityId } from "modules/facility/facilityHooks";
import { useScorecardsBySearch } from "modules/scorecard-lookup/scorecardLookupHooks";
import debounce from "debounce-promise";
import DatePicker from "react-datepicker";
import AsyncSelect from "react-select/async";
import ScorecardSearchFormattedOptions from "./ScorecardSearchFormattedOptions";
import SpinnerAnimation from "components/svg/SpinnerAnimation";
import ScorecardList from "./ScorecardList";

const ScorecardSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentFacilityId = useGetCurrentLocalFacilityId();

  const [selectedPlayers, setSelectedPlayers] = useState<PlayerUser[]>();
  const [
    scoreCardSearchData,
    setScorecardSearchData,
  ] = useState<ScorecardPayload>({
    userIds: [],
    startDate: new Date(DateTime.local().minus({ days: 30 }).toLocaleString()),
    endDate: new Date(),
  });
  const [
    scorecards,
    scorecardSearchQueryState,
    refreshScorecardSearch,
  ] = useScorecardsBySearch(currentFacilityId, scoreCardSearchData);

  const getAsyncOptions = (inputValue: string) => {
    return new Promise<PlayerUser[]>((resolve) => {
      if (inputValue.length > 3) {
        const options = userSearch(inputValue);
        resolve(options);
      } else {
        const options: PlayerUser[] = [];
        resolve(options);
      }
    });
  };

  const loadOptions = (inputValue: string) => {
    return getAsyncOptions(inputValue);
  };

  const debouncedLoadOptions = debounce(loadOptions, 500, {
    leading: true,
  });

  const handleSearchOnChange = async () => {
    await dismissCachedScorecards();
    await refreshScorecardSearch();
  };

  function usehandleSelectUser(selectedOptions: any) {
    dismissCachedScorecards();
    setSelectedPlayers(selectedOptions);
    if ((selectedOptions as PlayerUser[]).length > 0) {
      setScorecardSearchData({
        ...scoreCardSearchData,
        userIds: (selectedOptions as PlayerUser[]).map((player) => player.id),
      });
    }
  }

  const dismissCachedScorecards = () => {
    dispatch(
      updateEntities({
        scorecards: (oldValue) => {
          if (currentFacilityId)
            delete oldValue?.byFacilityId[currentFacilityId];
          return oldValue;
        },
      })
    );
  };

  const handleClearScorecards = () => {
    setSelectedPlayers([]);
    setScorecardSearchData({
      userIds: [""],
      startDate: new Date(
        DateTime.local().minus({ days: 30 }).toLocaleString()
      ),
      endDate: new Date(),
    });
    dismissCachedScorecards();
  };

  return (
    <>
      <div className="flex flex-row mt-8 bg-brandLightGrey dark:bg-zinc-600 rounded-full p-1 py-1.5 shadow border border-gray-100">
        <div className="flex flex-none w-1/2 mr-1">
          <AsyncSelect
            RequireAtLeastOne
            isMulti
            backspaceRemovesValue={false}
            noOptionsMessage={() => "No users found."}
            loadOptions={(query) => debouncedLoadOptions(query)}
            getOptionValue={(player: PlayerUser) => JSON.stringify(player)}
            filterOptions={false}
            formatOptionLabel={(option, { context }) => {
              return (
                <ScorecardSearchFormattedOptions
                  value={option}
                  context={context}
                />
              );
            }}
            styles={scorcardSearchStyle}
            placeholder={"Search by name and date range"}
            autoFocus
            id="lockerUserEditFormSearchField"
            key={`lockerUserEditFormSearchField`}
            className="w-full"
            inputId="reactSelectSearch"
            classNamePrefix={"react-select"}
            tabIndex="1"
            value={selectedPlayers}
            onChange={(options) => usehandleSelectUser(options)}
          />
        </div>
        <div className="flex flex-row mt-0.5 gap-1">
          <div className="flex w-2/5 bg-white rounded-full">
            <DatePicker
              onChange={(date: any) =>
                setScorecardSearchData({
                  ...scoreCardSearchData,
                  startDate: date,
                })
              }
              dateFormat="MMM d, yyyy"
              className={styles.datePicker}
              placeholderText="📅 Start"
              showTimeSelect
              selected={scoreCardSearchData.startDate}
              tabIndex={2}
              calendarClassName="dark:bg-zinc-800 dark:text-white hover:text-black"
              dayClassName={() =>
                "dark:bg-zinc-800  dark:text-white hover:text-black"
              }
              timeClassName={() =>
                "dark:bg-zinc-800 dark:text-white hover:text-black"
              }
            />
          </div>
          <div className="flex w-2/5">
            <DatePicker
              onChange={(date: any) =>
                setScorecardSearchData({
                  ...scoreCardSearchData,
                  endDate: date,
                })
              }
              dateFormat="MMM d, yyyy"
              className={styles.datePicker}
              placeholderText="📅 End"
              showTimeSelect
              selected={scoreCardSearchData.endDate}
              tabIndex={3}
              calendarClassName="dark:bg-zinc-800 dark:text-white hover:text-black"
              dayClassName={() =>
                "dark:bg-zinc-800  dark:text-white hover:text-black"
              }
              timeClassName={() =>
                "dark:bg-zinc-800 dark:text-white hover:text-black"
              }
            />
          </div>
          <div className="flex w-auto">
            <button
              className={styles.scorecardSearchButton}
              onClick={handleSearchOnChange}
              tabIndex={4}
            >
              <IoSearchOutline className="size-4 font-normal" />
            </button>
          </div>
        </div>
      </div>

      {scoreCardSearchData.userIds?.length &&
      scorecardSearchQueryState.isPending ? (
        <div className="flex items-center h-1/2 w-full mt-8">
          <SpinnerAnimation color={"#f2f2e6"} />
        </div>
      ) : (
        <>
          {scorecards.length === 0 && selectedPlayers && (
            <div className="flex flex-row pt-4 ">
              <span className="text-brandGreen rounded-full poppins text-xs px-4 py-2">
                No scorecards found
              </span>
            </div>
          )}
          {scorecards?.length > 0 && (
            <div className="flex flex-row pt-4 ">
              <button
                className=" bg-darkCream text-brandGreen rounded-full poppins shadow text-xs px-4 py-2"
                onClick={() => handleClearScorecards()}
              >
                Clear
              </button>
            </div>
          )}
          <ScorecardList />
        </>
      )}
    </>
  );
};
export default ScorecardSearch;
